import { getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import moment from "moment"
import { filterURL, getBlockData, getImageMeta, getTaxonomy, isExternalLink } from "."
import getConfigDisplayData from "./getConfigDisplayData"
import { getApiDomainAndLang } from "@utils/baseApi"
import getSearchFacetsData from "./getSearchFacetsData"
import SEARCH_FACETS from "@helpers/constants/searchFacets"

const getNewsCategoryFilterData = async (serverData?: any) => {
  let CategoryData = await getConfigDisplayData(serverData)
  CategoryData = CategoryData?.data

  if (CategoryData) {
    const CategoryLabelData = {
      searchLabel: CategoryData.news_n_stories_what_are_you_looking ?? null,
      searchPlaceholder: CategoryData.news_n_stories_search_placeholder_text ?? null,
      searchCTAText: CategoryData.news_n_stories_search_cta_text ?? null,
      AlphaLabel: CategoryData?.news_n_stories_all_category_text ?? null,
      FormLabel: CategoryData?.news_n_stories_from_text ?? null,
      clearLabel: CategoryData?.news_n_stories_search_clear_all_text ?? null,
      other_category_text: CategoryData?.news_n_stories_other_category_text ?? null,
      resultsFoundText: CategoryData?.news_n_stories_search_results_found ?? null,
      noResultsFoundText: CategoryData?.news_n_stories_no_results_found_title ?? null,
      noResultsFoundDescription: CategoryData?.news_n_stories_no_results_found_body?.value ?? null,
      unSelectItemsLabel: CategoryData?.search_unselect_items ?? null,
      applyAllfilters: CategoryData?.news_n_stories_apply_all_filters ?? null,
      itemsPerPage: CategoryData?.news_n_stories_count_for_each_page ?? null,
      dropdown_placeholder_text: CategoryData?.news_n_stories_select_option_text ?? "",
      news_stories_search_default_config: CategoryData?.news_stories_search_default_config ?? "",
      news_stories_search_facets: CategoryData?.news_stories_search_facets ?? [],
      news_n_stories_label_profession: CategoryData?.news_n_stories_label_profession ?? "",
      news_n_stories_label_disease_area: CategoryData?.news_n_stories_label_disease_area ?? "",
      news_n_stories_label_treatment_area: CategoryData?.news_n_stories_label_treatment_area ?? "",
      news_n_stories_label_active_ingredient:
        CategoryData?.news_n_stories_label_active_ingredient ?? "",
      news_n_stories_label_medication_type:
        CategoryData?.news_n_stories_label_medication_type ?? "",
      news_n_stories_label_education_type: CategoryData?.news_n_stories_label_education_type ?? "",
      news_n_stories_label_news_category: CategoryData?.news_n_stories_label_news_category ?? "",
      news_n_stories_label_audience: CategoryData?.news_n_stories_label_audience ?? "",
      filter_toggle_label: CategoryData?.filter_toggle_label ?? "",
    }
    return CategoryLabelData
  }

  return null
}
const filterPaywalledProducts = (eventCards: any) => {
  return eventCards?.filter(
    ({ specialityId,newsStoriesContent,imageSrc,styledimage
      }: any) => {
      return !(
        newsStoriesContent.heading == null &&
        newsStoriesContent.text == null &&
        newsStoriesContent.date == null &&
        newsStoriesContent.buttonLabel
        == null &&
        newsStoriesContent.buttonUrl
        == null &&
        newsStoriesContent.taxonomies.length
        == 0 &&
        specialityId == null &&
        imageSrc == null &&
        styledimage == null
      )
    },
  )
}

export const getFormattedNewsCardsData = async (url: any) => {
  let latestNewsStoriesCards = await getBlockData(url)
  const totalCount = Number(latestNewsStoriesCards?.meta?.count) ?? 0
  const itemsPerPage = Number(latestNewsStoriesCards?.number_of_items_per_page) ?? 0
  const fieldPaywallRoles = getAllRoles(latestNewsStoriesCards)
  latestNewsStoriesCards = latestNewsStoriesCards?.data ?? []
  const { lang } = getApiDomainAndLang()

  if (latestNewsStoriesCards) {
    latestNewsStoriesCards = await Promise.all(
      latestNewsStoriesCards?.map(async (newsStoriesCard: any) => {
        const isChildPaywalled = isPaywallProtected(
          getNestedObject(newsStoriesCard, "attributes.enable_paywall_block_level"),
        )?.isPaywallProtectedFlag

        if (isChildPaywalled) {
          return null
        }
        const imagePaywallData = isPaywallProtected(newsStoriesCard?.relationships?.field_nas_image)
        const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
          ? getPaywalledImgData(imagePaywallData?.errorObj)
          : getImageMeta(newsStoriesCard?.relationships?.field_nas_image)

        const taxonomies = await getTaxonomy(
          newsStoriesCard?.relationships?.field_category?.links?.related?.href,
          true,
        )
        const specialityId = await getNestedObject(
          newsStoriesCard,
          "relationships.field_news_category.data.meta.drupal_internal__target_id",
        )
        const createdDate =
          moment(newsStoriesCard?.attibutes?.created).locale(lang).format("YYYY") ?? null
          const date = newsStoriesCard?.attributes?.field_news_published_date ?? null

        return {
          newsStoriesContent: {
            id: newsStoriesCard?.attributes?.drupal_internal__nid ?? "",
            taxonomies: taxonomies ?? null,
            heading: isPaywallProtected(newsStoriesCard?.attributes?.title).isPaywallProtectedFlag
              ? null
              : newsStoriesCard?.attributes?.title ?? null,
            text: newsStoriesCard?.attributes?.body?.processed
              ? newsStoriesCard?.attributes?.body?.processed
              : null,
            date: isPaywallProtected(newsStoriesCard?.attributes?.field_news_published_date)
              .isPaywallProtectedFlag
              ? null
              : date,
            buttonLabel: newsStoriesCard?.attributes?.field_cta_nas?.title
              ? newsStoriesCard?.attributes?.field_cta_nas?.title
              : null,
            buttonUrl: newsStoriesCard?.attributes?.field_cta_nas?.uri
              ? filterURL(newsStoriesCard?.attributes?.field_cta_nas?.uri)
              : null,
            bookmark: {
              addText: "Add Bookmark",
              removeText: "Remove Bookmark",
              bookmarkStatus: false,
              isButton: false,
            },
            isButtonUrlExternal: newsStoriesCard?.attributes?.field_cta_nas?.options?.attributes
              ?.target
              ? isExternalLink(
                  newsStoriesCard?.attributes?.field_cta_nas?.options?.attributes?.target,
                )
              : null,
          },
          specialityId,
          createdDate: isPaywallProtected(newsStoriesCard?.attibutes?.created)
            .isPaywallProtectedFlag
            ? null
            : createdDate,
          imageSrc: imageUrl ?? null,
          imgAlt: alt ?? null,
          styledimage: imageStyledUrl ?? null,
        }
      }),
    )
  }
  return {
    latestNewsStoriesCards: filterPaywalledProducts(latestNewsStoriesCards),
    totalCount,
    itemsPerPage,
    fieldPaywallRoles,
  }
}

const getNewsStoriesListData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  const newsCategoryFilterData = await getNewsCategoryFilterData(serverData)
  const searchFacets = await getSearchFacetsData(newsCategoryFilterData, SEARCH_FACETS?.NEWS_STORIES)

  const nasFilterData = {
    advanceFilterData: {
      buttonText: newsCategoryFilterData?.searchLabel,
      filtersOptions: searchFacets,
      filtersLabels: {
        searchLabel: newsCategoryFilterData?.searchLabel ?? "",
        searchPlaceholder: newsCategoryFilterData?.searchPlaceholder ?? "",
        SearchCTAText: newsCategoryFilterData?.searchCTAText ?? "",
        unselectFiltersCTA: newsCategoryFilterData?.clearLabel ?? "",
        resultsFoundLabel: newsCategoryFilterData?.resultsFoundText ?? "",
        noResultsFoundLabel: newsCategoryFilterData?.noResultsFoundText ?? "",
        noResultsFoundDescription: newsCategoryFilterData?.noResultsFoundDescription ?? "",
        applyFiltersCTA: newsCategoryFilterData?.applyAllfilters ?? "",
        clearAllFilters: newsCategoryFilterData?.clearLabel ?? "",
        unSelectItemsLabel: newsCategoryFilterData?.unSelectItemsLabel ?? "",
        enableToggle: newsCategoryFilterData?.news_stories_search_default_config ?? "",
        filterIconName: newsCategoryFilterData?.filter_toggle_label ?? "",
      },
    },
  }

  return {
    nasFilterData,
  }
}

export default getNewsStoriesListData
