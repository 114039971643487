import { getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import moment from "moment"
import { filterURL, getBlockData, getImageMeta, isExternalLink } from "."
import getConfigDisplayData from "./getConfigDisplayData"
import getSearchFacetsData from "./getSearchFacetsData"
import SEARCH_FACETS from "@helpers/constants/searchFacets"


const getTherapyConfigData = async (serverData?: any) => {
  let CategoryData = await getConfigDisplayData(serverData)
  CategoryData = CategoryData?.data

  if (CategoryData) {
    const CategoryLabelData = {
      searchLabel: CategoryData.therapy_what_are_you_looking ?? null,
      searchPlaceholder: CategoryData.therapy_search_placeholder_text ?? null,
      searchCTAText: CategoryData.therapy_search_cta_text ?? null,
      AlphaLabel: CategoryData?.therapy_all_category_text ?? null,
      FormLabel: CategoryData?.therapy_from_text ?? null,
      clearLabel: CategoryData?.therapy_search_clear_all_text ?? null,
      other_category_text: CategoryData?.therapy_other_category_text ?? null,
      selectOptionText: CategoryData?.therapy_select_option_text ?? null,
      resultsFoundText: CategoryData?.therapy_search_results_found ?? null,
      noResultsFoundText: CategoryData?.therapy_no_results_found_title ?? null,
      noResultsFoundDescription: CategoryData?.therapy_no_results_found_body?.value,
      applyAllfilters: CategoryData.news_n_stories_apply_all_filters ?? null,
      itemsPerPage: CategoryData?.news_n_stories_count_for_each_page ?? null,
      unSelectItemsLabel: CategoryData?.search_unselect_items ?? null,
      therapy_areas_search_default_config:
        CategoryData?.therapy_areas_search_default_config ?? null,
      therapy_areas_search_facets: CategoryData?.therapy_areas_search_facets ?? [],
      therapy_label_disease_area: CategoryData?.therapy_label_disease_area ?? null,
      therapy_label_treatment_area: CategoryData?.therapy_label_treatment_area ?? null,
      therapy_label_profession: CategoryData?.therapy_label_profession ?? null,
      therapy_label_medication_type: CategoryData?.therapy_label_medication_type ?? null,
      therapy_label_audience: CategoryData?.therapy_label_audience ?? null,
      filter_toggle_label: CategoryData?.filter_toggle_label ?? null,
    }
    return CategoryLabelData
  }

  return null
}
const filterPaywalledProducts = (therapyCards: any) => {
  return therapyCards?.filter(
    ({ specialityId,therapyCardContent,image
      }: any) => {
      return !(
        therapyCardContent.heading == null &&
        therapyCardContent.text == null &&
        therapyCardContent.buttonLabel
        == null &&
        therapyCardContent.buttonUrl
        == "" &&
        specialityId == null &&
        image.src == "" &&
        image.styledimage == ""
      )
    },
  )
}

export const getFormattedTherapyCardsData = async (url: any) => {
  let therapyCards = await getBlockData(url)
  const totalCount = Number(therapyCards?.meta?.count) ?? 0
  const itemsPerPage = Number(therapyCards?.number_of_items_per_page) ?? 0
  const fieldPaywallRoles = getAllRoles(therapyCards)
  therapyCards = therapyCards?.data ?? []
  if (therapyCards) {
    therapyCards = await Promise.all(
      therapyCards?.map(async (therapyCard: any) => {
        const isChildPaywalled = isPaywallProtected(
          getNestedObject(therapyCard, "attributes.enable_paywall_block_level"),
        )?.isPaywallProtectedFlag

        if (isChildPaywalled) {
          return null
        }
        const imagePaywallData = isPaywallProtected(
          therapyCard?.relationships?.field_therapy_overview_image,
        )
        const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
          ? getPaywalledImgData(imagePaywallData?.errorObj)
          : getImageMeta(therapyCard?.relationships?.field_therapy_overview_image)

        const specialityId =
          therapyCard?.relationships?.field_specialty?.data?.[0]?.meta
            ?.drupal_internal__target_id ?? null
        const createdDate = isPaywallProtected(therapyCard?.attibutes?.created)
          .isPaywallProtectedFlag
          ? null
          : moment(therapyCard?.attibutes?.created).format("YYYY") ?? null

        return {
          therapyCardContent: {
            heading: isPaywallProtected(therapyCard?.attributes?.title).isPaywallProtectedFlag
              ? null
              : therapyCard?.attributes?.title ?? null,
            text: therapyCard?.attributes?.body?.processed
              ? therapyCard?.attributes?.body?.processed
              : null,
            buttonLabel: isPaywallProtected(therapyCard?.attributes?.field_more_info)
              .isPaywallProtectedFlag
              ? null
              : therapyCard?.attributes?.field_more_info ?? null,
            buttonUrl: therapyCard?.attributes?.path?.alias
              ? filterURL(therapyCard?.attributes?.path?.alias)
              : "",
            isButtonUrlExternal: therapyCard?.attributes?.path?.alias
              ? isExternalLink(therapyCard?.attributes?.path?.alias)
              : false,
            bookmark: {
              addText: "Add Bookmark",
              removeText: "Remove Bookmark",
              bookmarkStatus: true,
              isButton: false,
            },
          },
          specialityId,
          createdDate,
          image: {
            src: imageUrl ?? null,
            alt: alt ?? null,
            styledimage: imageStyledUrl ?? null,
          },
        }
      }),
    )
  }
  return {
    therapyCards: filterPaywalledProducts(therapyCards),
    totalCount,
    itemsPerPage,
    fieldPaywallRoles,
  }
}

const getTherapyListData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  const therapyConfigData = await getTherapyConfigData(serverData)
  const searchFacets = await getSearchFacetsData(therapyConfigData, SEARCH_FACETS?.THERAPY)

  const therapyFilterData = {
    advanceFilterData: {
      filtersOptions: searchFacets,
      filtersLabels: {
        searchLabel: therapyConfigData?.searchLabel ?? "",
        searchPlaceholder: therapyConfigData?.searchPlaceholder ?? "",
        SearchCTAText: therapyConfigData?.searchCTAText ?? "",
        unselectFiltersCTA: therapyConfigData?.clearLabel ?? "",
        resultsFoundLabel: therapyConfigData?.resultsFoundText ?? "",
        noResultsFoundLabel: therapyConfigData?.noResultsFoundText ?? "",
        noResultsFoundDescription: therapyConfigData?.noResultsFoundDescription ?? "",
        applyFiltersCTA: therapyConfigData?.applyAllfilters ?? "",
        unSelectItemsLabel: therapyConfigData?.unSelectItemsLabel ?? "",
        enableToggle: therapyConfigData?.therapy_areas_search_default_config ?? "",
        filterIconName: therapyConfigData?.filter_toggle_label ?? "",
      },
    },
    filterTitle: "",
  }

  return {
    therapyFilterData,
  }
}
export default getTherapyListData
