import {
  getAllRoles,
  getPaywalledImgData,
  isPaywallProtected,
} from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import moment from "moment"
import { filterURL, getBlockData, getImageMeta, getTaxonomy, isExternalLink } from "."
import getEventsFilterConfig from "./getEventsFilterConfig"
import { getApiDomainAndLang } from "@utils/baseApi"
import getSearchFacetsData from "./getSearchFacetsData"
import SEARCH_FACETS from "@helpers/constants/searchFacets"
import { EVENT_TYPE } from "@helpers/constants/generic"
import { getScoutEventData } from "./getScoutEventData"

const filterPaywalledProducts = (eventCards: any) => {
  return eventCards?.filter(
    ({ specialityId, eventCardVerticalContent, eventCardVerticalImage
    }: any) => {
      return !(
        eventCardVerticalContent.heading == null &&
        eventCardVerticalContent.text == null &&
        eventCardVerticalContent.time == null &&
        eventCardVerticalContent.primaryButtonLabel
        == null &&
        eventCardVerticalContent.primaryButtonUrl
        == "" &&
        eventCardVerticalContent.secondaryButtonLabel == null &&
        eventCardVerticalContent.secondaryButtonUrl == "" &&
        eventCardVerticalContent.contentText == null &&
        eventCardVerticalContent.startDate == null &&
        eventCardVerticalContent.endDate == null &&
        eventCardVerticalContent.eventType == null &&
        eventCardVerticalContent.taxonomies.length
        == 0 &&
        specialityId == null &&
        eventCardVerticalImage.src == "" &&
        eventCardVerticalImage.styledimage == ""
      )
    },
  )
}

export const getFormattedEventCardsData = async (url: any) => {
  let eventFilterData = await getBlockData(url)
  const totalCount = Number(eventFilterData?.meta?.count) ?? 0
  const itemsPerPage = Number(eventFilterData?.number_of_items_per_page) ?? 0
  const fieldPaywallRoles = getAllRoles(eventFilterData)
  eventFilterData = eventFilterData?.data ?? []
  const { lang } = getApiDomainAndLang()

  let eventCards: any = []
  if (eventFilterData) {
    eventCards = await Promise.all(
      eventFilterData?.map(async (eventCard: any, key: number) => {
        const isChildPaywalled = isPaywallProtected(
          getNestedObject(eventCard, "attributes.enable_paywall_block_level"),
        )?.isPaywallProtectedFlag

        if (isChildPaywalled) {
          return null
        }
        const imagePaywallData = isPaywallProtected(eventCard?.relationships?.field_event_banner)
        const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
          ? getPaywalledImgData(imagePaywallData?.errorObj)
          : getImageMeta(eventCard?.relationships?.field_event_banner)
        const taxonomies = await getTaxonomy(
          eventCard?.relationships?.field_category?.links?.related?.href,
          true,
        )
        const specialityId =
          eventCard?.relationships?.field_specialty?.data?.[0]?.meta?.drupal_internal__target_id ??
          null
        const createdDate =
          moment(eventCard?.attributes?.created).locale(lang).format("YYYY") ?? null

        const startDate = eventCard?.attributes?.field_event_dates?.value ?? null
        const endDate = eventCard?.attributes?.field_event_dates?.end_value ?? null

        const eventType = eventCard?.attributes?.field_event_options ?? null
        const scoutData = eventType === EVENT_TYPE.SCOUT_EVENT ? getScoutEventData(eventCard) : null

        return {
          eventCardVerticalContent: {
            id: eventCard?.attributes?.drupal_internal__nid ?? "",
            taxonomies: taxonomies ?? null,
            heading: isPaywallProtected(eventCard?.attributes?.title ?? null).isPaywallProtectedFlag
              ? null
              : eventCard?.attributes?.title ?? null,
            bookmark: isPaywallProtected(eventCard?.attributes?.status ?? null)
              .isPaywallProtectedFlag
              ? null
              : eventCard?.attributes?.status ?? null,
            text: eventCard?.attributes?.body?.value ?? null,
            primaryButtonLabel: eventCard?.attributes?.field_primary_cta_link?.title ?? null,
            primaryButtonUrl: filterURL(eventCard?.attributes?.field_primary_cta_link?.uri) ?? null,
            primaryIsExternalUrl:
              isExternalLink(
                eventCard?.attributes?.field_primary_cta_link?.options?.attributes?.target,
              ) ?? null,
            secondaryButtonLabel: eventCard?.attributes?.field_secondary_cta_link?.title ?? null,
            secondaryButtonUrl:
              filterURL(eventCard?.attributes?.field_secondary_cta_link?.uri) ?? null,
            secondaryIsExternalUrl:
              isExternalLink(
                eventCard?.attributes?.field_secondary_cta_link?.options?.attributes?.target,
              ) ?? null,
            startDate: isPaywallProtected(eventCard?.attributes?.field_event_dates)
              .isPaywallProtectedFlag
              ? null
              : startDate,
            endDate: isPaywallProtected(eventCard?.attributes?.field_event_dates)
              .isPaywallProtectedFlag
              ? null
              : endDate,
            contentText: isPaywallProtected(eventCard?.attributes?.field_location ?? null)
              .isPaywallProtectedFlag
              ? null
              : eventCard?.attributes?.field_location ?? null,
            time: isPaywallProtected(eventCard?.attributes?.field_duration ?? null)
              .isPaywallProtectedFlag
              ? null
              : eventCard?.attributes?.field_duration ?? null,
            scoutData,
            eventType,
          },
          eventCardVerticalImage: {
            src: imageUrl ?? null,
            alt: alt ?? null,
            styledimage: imageStyledUrl ?? null,
          },
          eventCardVDesktopColumn: 4,
          eventCardVTabletColumn: 6,
          eventCardVMobileColumn: 12,
          specialityId,
          createdDate: isPaywallProtected(eventCard?.attributes?.created).isPaywallProtectedFlag
            ? null
            : createdDate,
        }
      }),
    )
  }
  return {
    eventCards: filterPaywalledProducts(eventCards),
    totalCount,
    itemsPerPage,
    fieldPaywallRoles,
  }
}

const getEventVerticalCardData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  const eventfilteroptions = await getEventsFilterConfig(serverData)
  const searchFacetData = await getSearchFacetsData(eventfilteroptions, SEARCH_FACETS?.EVENTS)
  const eventVerticalFilterData = {
    advanceFilterData: {
      filtersOptions: searchFacetData,
      filtersLabels: {
        searchLabel: eventfilteroptions?.search_label ?? "",
        searchPlaceholder: eventfilteroptions?.search_placeholder_text ?? "",
        SearchCTAText: eventfilteroptions?.search_cta_text ?? "",
        unselectFiltersCTA: eventfilteroptions?.search_clear_all_text ?? "",
        resultsFoundLabel: eventfilteroptions?.search_results_found ?? "",
        noResultsFoundLabel: eventfilteroptions?.no_results_found_title ?? "",
        noResultsFoundDescription: eventfilteroptions?.noResultsFoundDescription ?? "",
        applyFiltersCTA: eventfilteroptions?.applyAllfilters ?? "",
        unSelectItemsLabel: eventfilteroptions?.unSelectItemsLabel ?? "",
        enableToggle: eventfilteroptions?.events_search_default_config ?? "",
        filterIconName: eventfilteroptions?.filter_toggle_label ?? "",
      },
    },
    filterTitle: eventfilteroptions?.event_title,
  }

  return {
    eventVerticalFilterData,
  }
}

export default getEventVerticalCardData
